#StudentSignUpPage {
}

#StudentSignUpPage > div {
    margin-top: 1rem;
}

#StudentSignUpPage div.email {
    word-break: break-all;
}

#StudentSignUpPage > div.student-info > div > div:last-child {
    border-left: solid 1px black;
}

#StudentSignUpPage > div.buttons {
    text-align: center;
}