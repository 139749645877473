html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

input.error {
  border-color: red;
}

div.error {
  color: red;
}

div.success {
  color: green;
}

h3.center{
  text-align: center;
}

table {
  width: 100%;
  margin: 1em 0;
}

tr {
  border-bottom: 1px dotted black;
}

thead > tr {
  border-bottom: 1px solid black;
}

tbody > tr:last-child, form tr {
  border-bottom: 0 !important;
}

th, td {
  padding: 0.25em 1em;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}