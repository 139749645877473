#LoginPage {
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 33%;
    height: 33%;
}

#LoginPage button {
    min-width: 33%;
    margin-top: 2rem;
}
